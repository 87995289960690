import React, { useEffect, useState } from "react";
import ValidateApprover from "./ValidateApprover.js";
import moment from "moment";

import axios from "axios";
import { Button } from "primereact/button";
import { APIService } from "../service/APIService";
import "./FormDemo.css";

const Dashboard = (props) => {
    const [supplierHash, setSupplierHash] = useState("");
    const [supplierObj, setSupplierObj] = useState({});
    const [loading1, setLoading1] = useState(true);
    const [district, setDistrict] = useState("");
    const [branch, setBranch] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [supplierApprovalStatus, setSupplierApprovalStatus] = useState(true);
    const [appUrl, setAppUrl] = useState("");
    const [errorStatus, setErrorStatus] = useState(false);

    useEffect(() => {
        setLoading1(true);

        let hash = props.location.pathname.slice(1);
        setSupplierHash(hash);
        let supplierHash = {
            supplierHash: hash,
        };

        const getCookie = (cname) => {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(";");
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        };

        let appUrl = getCookie("APP_EXTERNAL_URL");
        //get cookies

        // console.log("appUrl", appUrl);
        setAppUrl(appUrl);
        axios
            .put(appUrl + "/api/getEventFundsBySupplierHash", supplierHash)
            .then((res) => {
                // check res.data.type = graybar or supplier

                if (res.data.status === "Success") {
                    console.log("58", res.data);
                    setErrorStatus(false);
                    res.data.supplierApprovalStatus === "Approved" ? setSupplierApprovalStatus(true) : setSupplierApprovalStatus(false);
                    setSupplierObj(res.data);
                    setDistrict(JSON.parse(res.data.district).districtName);
                    setBranch(res.data.branch);
                    setStartDate(moment.utc(res.data.dateStart).format("ll"));
                    setEndDate(moment.utc(res.data.dateEnd).format("ll"));
                    // console.log("66", "end of success");
                } else {
                    setErrorStatus(true);
                    // console.log("68", "setErrorStatus hit");
                }
            })
            .catch((err) => {
                setErrorStatus(true);
                // console.log("71", err);
            })
            .finally(() => {
                setLoading1(false);
            });
        // }
    }, []);

    const getCookie = (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };

    const formatCurrency = (value = 0) => {
        return Intl.NumberFormat("en-US", { style: "currency", currency: "USD", currencySign: "accounting" }).format(value);
    };

    return (
        <div>
            {loading1 && (
                <div className="p-card">
                    <div className="p-fluid formgrid grid">
                        <div className="col-12 md:col-12">
                            <div className="bg-blue-900 p-3">
                                <h2 className="flex align-items-center justify-content-center text-2xl font-bold text-white">Loading Approval Request </h2>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {errorStatus && !loading1 && (
                <div className="p-card">
                    <div className="p-fluid formgrid grid">
                        <div className="col-12 md:col-12">
                            <div className="bg-blue-900 p-3">
                                <h2 className="flex align-items-center justify-content-center text-2xl font-bold text-white">Invalid Approval Request </h2>
                            </div>
                        </div>
                    </div>
                    <div className="p-card-body">
                        <div className="p-fluid formgrid grid">
                            <div className="col-6 md:col-6">
                                <div className="p-card-title">This approval request was not found</div>
                                <div className="p-card-content">Please check email and link or contact your Graybar representative</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!errorStatus && !loading1 && (
                <div className="p-card">
                    <div className="p-fluid formgrid grid">
                        <div className="col-12 md:col-12">
                            <div className="bg-blue-900 p-3">
                                <h2 className="flex align-items-center justify-content-center text-2xl font-bold text-white">{supplierObj.supplier} Approval Request </h2>
                            </div>
                        </div>
                    </div>
                    <div className="p-card-body">
                        <div className="p-fluid formgrid grid">
                            <div className="col-12 md:col-6">
                                <div className="p-card-title">{supplierObj.sf918ID}</div>
                                {district}, {branch}
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="flex align-items-center justify-content-center text-2xl font-bold">Total Amount Requested:</div>
                                <div className="flex align-items-center justify-content-center text-900 font-bold text-5xl">{formatCurrency(supplierObj.amountRequested)}</div>
                            </div>
                        </div>
                        <div className="col-12 md:col-2">
                            <div className="p-card-subtitle"></div>
                        </div>
                        <div className="p-fluid formgrid grid">
                            {!supplierApprovalStatus && (
                                <div className="col-6 md:col-6">
                                    <div className="p-card-title">Approval Required for Event: {supplierObj.eventName}</div>
                                </div>
                            )}
                            {supplierApprovalStatus && (
                                <div className="col-6 md:col-6">
                                    <div className="p-card-title">
                                        {supplierObj.eventName} was approved by {supplierObj.supplierApprovalName}
                                    </div>
                                    <div className="p-card-content">Approver Email: {supplierObj.supplierApprovalEmail} </div>
                                    <div className="p-card-content">Approved: {supplierObj.dateSupplierApproval} </div>
                                </div>
                            )}
                            <div className="col-12 md:col-12">
                                <div className="p-card-content">Description: {supplierObj.description}</div>
                            </div>
                        </div>
                        <div className="p-fluid formgrid grid">
                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">Event Owner: {supplierObj.eventOwner}</div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    Dates: {startDate} - {endDate}
                                </div>
                            </div>
                        </div>
                    </div>
                    {!supplierApprovalStatus && (
                        <div className="p-card-body">
                            <ValidateApprover supplierHash={supplierHash} appUrl={appUrl} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Dashboard, comparisonFn);
