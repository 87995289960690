import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";
import { APIService } from "../service/APIService.js";

import "./FormDemo.css";

import axios from "axios";

const ValidateApprover = (props) => {
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});

    const formValues = {
        name: "",
        email: "",
        accept: false,
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({ formValues });

    const onSubmit = (data) => {
        data.supplierHash = props.supplierHash;
        let formDataObj = data;
        let appUrl = props.appUrl;
        formDataObj.supplierApprovalStatus = true;
        setFormData(formDataObj);
        setShowMessage(true);
        axios.put(appUrl + "/api/approveEvent", formDataObj).then((res) => window.location.reload(true));
        //.catch((err) => console.log(err));
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    const dialogFooter = (
        <div className="flex justify-content-center">
            <Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} />
        </div>
    );

    return (
        <div className="form-demo">
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ "960px": "80vw" }} style={{ width: "30vw" }}>
                <div className="flex justify-content-center flex-column pt-6 px-3">
                    <i className="pi pi-check-circle" style={{ fontSize: "5rem", color: "var(--green-500)" }}></i>
                    <h5>Confirmation Successful!</h5>
                    <p style={{ lineHeight: 1.5, textIndent: "1rem" }}>
                        <b>{formData.name}</b> has confirmed the usage of these funds for a Graybar event; Please check <b>{formData.email}</b> for your confirmation email.
                    </p>
                </div>
            </Dialog>
            <h5 className="p-card-title">Confirm Event Fund</h5>
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                <div className="py-4">
                    <span className="p-float-label">
                        <Controller name="name" control={control} defaultValue={""} rules={{ required: "Name is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} autoFocus className={classNames({ "p-invalid": fieldState.invalid })} />} />
                        <label htmlFor="name" className={classNames({ "p-error": errors.name })}>
                            Name*
                        </label>
                    </span>
                    {getFormErrorMessage("name")}
                </div>
                <div className="py-4">
                    <span className="p-float-label p-input-icon-right">
                        <i className="pi pi-envelope" />
                        <Controller
                            name="email"
                            control={control}
                            defaultValue={""}
                            rules={{ required: "Email is required.", pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "Invalid email address. E.g. example@email.com" } }}
                            render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />}
                        />
                        <label htmlFor="email" className={classNames({ "p-error": !!errors.email })}>
                            Email*
                        </label>
                    </span>
                    {getFormErrorMessage("email")}
                </div>
                <div className="field-checkbox">
                    <Controller name="accept" control={control} rules={{ required: true }} render={({ field, fieldState }) => <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                    <label htmlFor="accept" className={classNames({ "p-error": errors.accept })}>
                        I confirm the use of supplier funds for this event*
                    </label>
                </div>
                <Button type="submit" label="Submit" className="mt-2" />
            </form>
        </div>
    );
};

export default React.memo(ValidateApprover);
